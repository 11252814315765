import { AcceptType, FileUploadZone } from '@/common/components/FileUploader';
import MediaSwiperWrapper from '@/common/components/MediaSwiperWrapper';
import { dataUriToBlob, uploadFile } from '@/utils/file/files';
import { FileContentType, FileIdAndUrl, IFile } from '@/utils/file/type';
import type { FC } from 'react';
import { CustomFieldFileValueType } from './CustomFieldFileValueType';

type CustomFieldFileProps = {
  onAddCustomFieldFileValues: (fileValues: CustomFieldFileValueType[]) => void;
  onRemoveCustomFieldFileValue: (fileId: string, customFieldId: number) => void;
  onUpdateCustomFieldFileValue?: (
    fileId: string,
    params: {
      fileId: string;
      contentType: string;
      name: string;
      src: string;
    }
  ) => Promise<void>;
  customFieldId: number;
  customFieldFileValues: CustomFieldFileValueType[];
  getFileUploadUrls: (filesContentTypes: FileContentType[]) => Promise<FileIdAndUrl[]>;
};

const ACCEPT_TYPES: AcceptType[] = ['image', 'video', 'pdf'];

const CustomFieldFile: FC<CustomFieldFileProps> = (props: CustomFieldFileProps) => {
  const {
    onAddCustomFieldFileValues,
    onRemoveCustomFieldFileValue,
    getFileUploadUrls,
    onUpdateCustomFieldFileValue,
    customFieldId,
    customFieldFileValues,
  } = props;

  const onAddFiles = (files: IFile[]) => {
    const localFiles = files.map(({ id, file, fileData }) => ({
      customFieldId,
      fileId: id,
      name: file.name,
      contentType: file.type,
      src: fileData,
    }));

    onAddCustomFieldFileValues(localFiles);
  };

  const onClickRemoveIcon = (fileId: string) => {
    onRemoveCustomFieldFileValue(fileId, customFieldId);
  };

  return (
    <>
      <FileUploadZone
        addFiles={onAddFiles}
        getFileUploadUrls={getFileUploadUrls}
        acceptTypes={ACCEPT_TYPES}
        isMultiple
      />
      <MediaSwiperWrapper
        mediaFiles={customFieldFileValues}
        removeFileHandler={(fileId) => onClickRemoveIcon(fileId)}
        showEditInModal={!!onUpdateCustomFieldFileValue}
        editFileHandler={async (fileId, dataUri, contentType, name) => {
          const data = await getFileUploadUrls([{ contentType }]);
          const { id, url } = data[0];
          await uploadFile(url, dataUriToBlob(dataUri));

          await onUpdateCustomFieldFileValue?.(fileId, {
            fileId: id,
            contentType,
            name,
            src: dataUri,
          });
        }}
      />
    </>
  );
};

export default CustomFieldFile;
