import * as Types from '../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQueryVariables = Types.Exact<{
  assetId: Types.Scalars['Int']['input'];
}>;


export type GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery = (
  { __typename?: 'Query' }
  & { asset: (
    { __typename?: 'Asset' }
    & { stoppageReasons: Array<(
      { __typename?: 'StoppageReason' }
      & Pick<Types.StoppageReason, 'id' | 'name'>
    )> }
  ) }
);


export const GetAssetStoppageReasonForWorkOrderStoppageReasonPickerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAssetStoppageReasonForWorkOrderStoppageReasonPicker"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assetId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"asset"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assetId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"stoppageReasons"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery__
 *
 * To run a query within a React component, call `useGetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useGetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery(baseOptions: Apollo.QueryHookOptions<GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery, GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQueryVariables> & ({ variables: GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery, GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQueryVariables>(GetAssetStoppageReasonForWorkOrderStoppageReasonPickerDocument, options);
      }
export function useGetAssetStoppageReasonForWorkOrderStoppageReasonPickerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery, GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery, GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQueryVariables>(GetAssetStoppageReasonForWorkOrderStoppageReasonPickerDocument, options);
        }
export function useGetAssetStoppageReasonForWorkOrderStoppageReasonPickerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery, GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery, GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQueryVariables>(GetAssetStoppageReasonForWorkOrderStoppageReasonPickerDocument, options);
        }
export type GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQueryHookResult = ReturnType<typeof useGetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery>;
export type GetAssetStoppageReasonForWorkOrderStoppageReasonPickerLazyQueryHookResult = ReturnType<typeof useGetAssetStoppageReasonForWorkOrderStoppageReasonPickerLazyQuery>;
export type GetAssetStoppageReasonForWorkOrderStoppageReasonPickerSuspenseQueryHookResult = ReturnType<typeof useGetAssetStoppageReasonForWorkOrderStoppageReasonPickerSuspenseQuery>;
export type GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQueryResult = Apollo.QueryResult<GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQuery, GetAssetStoppageReasonForWorkOrderStoppageReasonPickerQueryVariables>;