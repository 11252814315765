import { UserType } from '@/common/components/UserSelectInput';
import { CustomFieldUser } from '@/common/components/customFields';
import { Maybe } from '@/common/types';
import type {
  IWorkOrderCustomFieldDateValueInput,
  IWorkOrderCustomFieldDatetimeValueInput,
  IWorkOrderCustomFieldFloatValueInput,
  IWorkOrderCustomFieldIntValueInput,
  IWorkOrderCustomFieldSelectValueInput,
  IWorkOrderCustomFieldTextValueInput,
  IWorkOrderCustomFieldUserValueInput,
} from '@/modules/workOrders/types/workOrder';
import type { IWorkOrderCustomField } from '@/modules/workOrders/types/workOrderCustomField';
import { deepCopy } from '@/utils/copy/copy';
import { formatDateToYYYYMMDDForInput, formatDateToYYYYMMDDHHmmForInput } from '@/utils/date/date';
import { FileContentType, FileIdAndUrl } from '@/utils/file/type';
import { Box, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import type { FC } from 'react';
import { useState } from 'react';
import CustomFieldDate from './CustomFieldDate';
import CustomFieldDatetime from './CustomFieldDatetime';
import CustomFieldFile from './CustomFieldFile';
import { CustomFieldFileValueType } from './CustomFieldFileValueType';
import CustomFieldFloat from './CustomFieldFloat';
import CustomFieldInt from './CustomFieldInt';
import CustomFieldSelect from './CustomFieldSelect';
import CustomFieldText from './CustomFieldText';

type CustomFieldItemProps = {
  customField: IWorkOrderCustomField;
  customFieldTextValues: IWorkOrderCustomFieldTextValueInput[];
  customFieldIntValues: IWorkOrderCustomFieldIntValueInput[];
  customFieldFloatValues: IWorkOrderCustomFieldFloatValueInput[];
  customFieldSelectValues: IWorkOrderCustomFieldSelectValueInput[];
  customFieldDatetimeValues: IWorkOrderCustomFieldDatetimeValueInput[];
  customFieldDateValues: IWorkOrderCustomFieldDateValueInput[];
  customFieldUserValues?: IWorkOrderCustomFieldUserValueInput[];
  changeCustomFieldTextValues: (
    customFieldTextValues: IWorkOrderCustomFieldTextValueInput[]
  ) => void;
  changeCustomFieldIntValues: (customFieldIntValues: IWorkOrderCustomFieldIntValueInput[]) => void;
  changeCustomFieldFloatValues: (
    customFieldFloatValues: IWorkOrderCustomFieldFloatValueInput[]
  ) => void;
  changeCustomFieldSelectValues: (
    customFieldSelectValues: IWorkOrderCustomFieldSelectValueInput[]
  ) => void;
  changeCustomFieldDatetimeValues: (
    customFieldDatetimeValues: IWorkOrderCustomFieldDatetimeValueInput[]
  ) => void;
  changeCustomFieldDateValues: (
    customFieldDateValues: IWorkOrderCustomFieldDateValueInput[]
  ) => void;
  changeCustomFieldUserValues: (
    customFieldUserValues: IWorkOrderCustomFieldUserValueInput[]
  ) => void;
  customFieldFileValues: CustomFieldFileValueType[];
  onAddCustomFieldFileValues: (fileValues: CustomFieldFileValueType[]) => void;
  onRemoveCustomFieldFileValue: (fileId: string, customFieldId: number) => void;
  onUpdateCustomFieldFileValue?: (
    fileId: string,
    params: {
      fileId: string;
      contentType: string;
      name: string;
      src: string;
    }
  ) => Promise<void>;
  assetId: Maybe<number>;
  getFileUploadUrls: (filesContentTypes: FileContentType[]) => Promise<FileIdAndUrl[]>;
  onCreateTextSuggest?: (label: string, text: string) => Promise<string | false>;
  suggestible?: boolean;
};

const CustomFieldItem: FC<CustomFieldItemProps> = (props: CustomFieldItemProps) => {
  const {
    customField,
    customFieldTextValues = [],
    customFieldIntValues = [],
    customFieldFloatValues = [],
    customFieldSelectValues = [],
    customFieldDatetimeValues = [],
    customFieldDateValues = [],
    customFieldFileValues = [],
    customFieldUserValues = [],
    changeCustomFieldTextValues,
    changeCustomFieldIntValues,
    changeCustomFieldFloatValues,
    changeCustomFieldSelectValues,
    changeCustomFieldDatetimeValues,
    changeCustomFieldDateValues,
    changeCustomFieldUserValues,
    onAddCustomFieldFileValues,
    onRemoveCustomFieldFileValue,
    getFileUploadUrls,
    onUpdateCustomFieldFileValue,
    onCreateTextSuggest,
    assetId,
    suggestible = false,
  } = props;

  const [customFieldErrorMessage, setCustomFieldErrorMessage] = useState<string>('');

  const handleCustomFieldError = (customFieldId: number, errorMessage: string) => {
    if (customFieldId !== customField.id) return;
    setCustomFieldErrorMessage(errorMessage);
  };

  const onCustomFieldTextValueChange = (input: { customFieldId: number; value: string }) => {
    const customFieldLocalTextValues = deepCopy(customFieldTextValues);

    const { customFieldId, value } = input;
    const index = customFieldLocalTextValues.findIndex(
      (value) => value.customFieldId === input.customFieldId
    );

    if (index >= 0) {
      customFieldLocalTextValues[index].value = value;
    } else {
      customFieldLocalTextValues.push({ customFieldId, value });
    }

    changeCustomFieldTextValues(
      customFieldLocalTextValues.map((value) => {
        return {
          customFieldId: value.customFieldId,
          value: value.value,
        };
      })
    );
  };

  const onCustomFieldIntValueChange = (input: { customFieldId: number; value: number }) => {
    const customFieldLocalIntValues = deepCopy(customFieldIntValues);

    const { customFieldId, value } = input;
    const index = customFieldLocalIntValues.findIndex(
      (value) => value.customFieldId === input.customFieldId
    );

    if (index >= 0) {
      customFieldLocalIntValues[index].value = value;
    } else {
      customFieldLocalIntValues.push({ customFieldId, value });
    }

    changeCustomFieldIntValues(
      customFieldLocalIntValues.map((value) => {
        return {
          customFieldId: value.customFieldId,
          value: value.value,
        };
      })
    );
  };

  const onCustomFieldFloatValueChange = (input: { customFieldId: number; value: number }) => {
    const customFieldLocalFloatValues = deepCopy(customFieldFloatValues);

    const { customFieldId, value } = input;
    const index = customFieldLocalFloatValues.findIndex(
      (value) => value.customFieldId === input.customFieldId
    );

    if (index >= 0) {
      customFieldLocalFloatValues[index].value = value;
    } else {
      customFieldLocalFloatValues.push({ customFieldId, value });
    }

    changeCustomFieldFloatValues(
      customFieldLocalFloatValues.map((value) => {
        return {
          customFieldId: value.customFieldId,
          value: value.value,
        };
      })
    );
  };

  const onCustomFieldSelectValueChange = (input: {
    customFieldId: number;
    optionIds?: number[];
  }) => {
    const { customFieldId, optionIds } = input;
    // 一度全て削除してから、新しい値を追加する
    const customFieldLocalSelectValues = deepCopy(customFieldSelectValues).filter(
      (value) => value.customFieldId !== customFieldId
    );

    for (const optionId of optionIds ?? []) {
      if (optionId === undefined) continue;
      customFieldLocalSelectValues.push({ customFieldId, optionId });
    }

    changeCustomFieldSelectValues(
      customFieldLocalSelectValues.map((value) => {
        return {
          customFieldId: value.customFieldId,
          optionId: value.optionId,
        };
      })
    );
  };

  const onCustomFieldDateValueChange = (input: { customFieldId: number; value: string }) => {
    let customFieldLocalDateValues = deepCopy(customFieldDateValues);

    const value = new Date(input.value);
    const customFieldId = input.customFieldId;
    const index = customFieldLocalDateValues.findIndex(
      (value) => value.customFieldId === input.customFieldId
    );

    if (index >= 0) {
      if (input.value) {
        customFieldLocalDateValues[index].value = value;
      } else {
        customFieldLocalDateValues = customFieldLocalDateValues.filter((_, idx) => idx !== index);
      }
    } else {
      customFieldLocalDateValues.push({ customFieldId, value });
    }

    changeCustomFieldDateValues(customFieldLocalDateValues);
  };

  const onCustomFieldUserValueChange = (input: { customFieldId: number; userIds: string[] }) => {
    const { customFieldId, userIds } = input;
    // 一度全て削除してから、新しい値を追加する
    changeCustomFieldUserValues([
      ...customFieldUserValues.filter((value) => value.customFieldId !== customFieldId),
      ...(userIds ?? []).map((userId) => ({ customFieldId, userId })),
    ]);
  };

  const onCustomFieldDatetimeValueChange = (input: { customFieldId: number; value: string }) => {
    let customFieldLocalDatetimeValues = deepCopy(customFieldDatetimeValues);

    const value = new Date(input.value);
    const customFieldId = input.customFieldId;
    const index = customFieldLocalDatetimeValues.findIndex(
      (value) => value.customFieldId === input.customFieldId
    );

    if (index >= 0) {
      if (input.value) {
        customFieldLocalDatetimeValues[index].value = value;
      } else {
        customFieldLocalDatetimeValues = customFieldLocalDatetimeValues.filter(
          (_, idx) => idx !== index
        );
      }
    } else {
      customFieldLocalDatetimeValues.push({ customFieldId, value });
    }

    changeCustomFieldDatetimeValues(customFieldLocalDatetimeValues);
  };

  let defaultValue = '';
  let defaultOptionIds: number[] = [];
  let defaultUserIds: UserType[] = [];
  switch (customField.type) {
    case 'text':
      const customFieldTextValue = customFieldTextValues.find(
        (value) => value.customFieldId === customField.id
      );
      defaultValue = customFieldTextValue ? customFieldTextValue.value : '';
      break;
    case 'int':
      const customFieldIntValue = customFieldIntValues.find(
        (value) => value.customFieldId === customField.id
      );
      defaultValue = customFieldIntValue ? customFieldIntValue.value.toString() : '';
      break;
    case 'float':
      const customFieldFloatValue = customFieldFloatValues.find(
        (value) => value.customFieldId === customField.id
      );
      defaultValue = customFieldFloatValue ? customFieldFloatValue.value.toString() : '';
      break;
    case 'select':
      const customFieldSelectLocalValues = customFieldSelectValues.filter(
        (value) => value.customFieldId === customField.id
      );
      defaultOptionIds =
        customFieldSelectLocalValues.length > 0
          ? customFieldSelectLocalValues.map((value) => value.optionId)
          : [];
      break;
    case 'date':
      const customFieldDateValue = customFieldDateValues.find(
        (value) => value.customFieldId === customField.id
      );
      defaultValue = customFieldDateValue
        ? formatDateToYYYYMMDDForInput(customFieldDateValue.value)
        : '';
      break;
    case 'datetime':
      const customFieldDatetimeValue = customFieldDatetimeValues.find(
        (value) => value.customFieldId === customField.id
      );
      defaultValue = customFieldDatetimeValue
        ? formatDateToYYYYMMDDHHmmForInput(customFieldDatetimeValue.value)
        : '';
      break;
    case 'user':
      defaultUserIds = customFieldUserValues.filter(
        (value) => value.customFieldId === customField.id
      );
      break;
  }

  return (
    <Box bg='neutral.0' borderRadius='md' px={4} py={2} mx={2} my={4} key={customField.id}>
      <FormControl isInvalid={!!customFieldErrorMessage}>
        <FormLabel color='neutral.800'>{customField.label}</FormLabel>
        {customField.type === 'text' ? (
          <CustomFieldText
            customFieldId={customField.id}
            value={defaultValue}
            onChange={onCustomFieldTextValueChange}
            onCreateTextSuggest={(text: string) => {
              if (!onCreateTextSuggest) throw new Error();
              return onCreateTextSuggest(customField.label, text);
            }}
            suggestible={suggestible}
            customFieldTextConfig={customField.customFieldTextConfig}
          />
        ) : customField.type === 'float' ? (
          <CustomFieldFloat
            customFieldId={customField.id}
            value={defaultValue ? Number(defaultValue) : undefined}
            onChange={onCustomFieldFloatValueChange}
            customFieldNumberConfig={customField.customFieldFloatConfig}
            handleError={handleCustomFieldError}
          />
        ) : customField.type === 'int' ? (
          <CustomFieldInt
            customFieldId={customField.id}
            value={defaultValue ? Number(defaultValue) : undefined}
            onChange={onCustomFieldIntValueChange}
            customFieldNumberConfig={customField.customFieldIntConfig}
            handleError={handleCustomFieldError}
          />
        ) : customField.type === 'date' ? (
          <CustomFieldDate
            customFieldId={customField.id}
            value={defaultValue}
            onChange={onCustomFieldDateValueChange}
          />
        ) : customField.type === 'datetime' ? (
          <CustomFieldDatetime
            customFieldId={customField.id}
            value={defaultValue}
            onChange={onCustomFieldDatetimeValueChange}
          />
        ) : customField.type === 'select' ? (
          <CustomFieldSelect
            label={customField.label}
            optionIds={defaultOptionIds}
            customFieldId={customField.id}
            selectItems={customField.selectItems}
            customFieldSelectConfig={customField.customFieldSelectConfig}
            onChange={onCustomFieldSelectValueChange}
            assetId={assetId}
            defaults={customField.defaults}
          />
        ) : customField.type === 'user' ? (
          <CustomFieldUser
            label={customField.label}
            customFieldId={customField.id}
            userIds={defaultUserIds}
            onChange={onCustomFieldUserValueChange}
          />
        ) : (
          customField.type === 'file' && (
            <CustomFieldFile
              getFileUploadUrls={getFileUploadUrls}
              customFieldId={customField.id}
              customFieldFileValues={customFieldFileValues.filter(
                (value) => value.customFieldId === customField.id
              )}
              onAddCustomFieldFileValues={onAddCustomFieldFileValues}
              onRemoveCustomFieldFileValue={onRemoveCustomFieldFileValue}
              onUpdateCustomFieldFileValue={onUpdateCustomFieldFileValue}
            />
          )
        )}
        {customFieldErrorMessage && <FormErrorMessage>{customFieldErrorMessage}</FormErrorMessage>}
      </FormControl>
    </Box>
  );
};

export default CustomFieldItem;
