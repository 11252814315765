import { IDisplayUser } from '@/modules/users';

export const toUserInputs = (users?: IDisplayUser[]) => {
  if (!users) return [];
  return users.map((user) => ({ userId: user.id }));
};

export const toUsersText = (users?: IDisplayUser[]) => {
  if (!users) return '';
  return users.map((user) => user.name).join(', ');
};
