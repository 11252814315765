import { WorkOrderPriority } from '@/graphql/types';
import useTranslation from '@/utils/i18n/useTranslation';
import { Box, Flex, FlexProps, UseRadioProps, useRadio, useRadioGroup } from '@chakra-ui/react';
import { FC, ReactNode, useEffect } from 'react';
import { workOrderPriorityLabelOptions } from './WorkOrderPriorityLabel';

type RadioCardProps = {
  selectedBackgroundColor: string;
  showBorderRight?: boolean;
  children: ReactNode;
} & UseRadioProps;

const RadioCard: FC<RadioCardProps> = (props: RadioCardProps) => {
  const { selectedBackgroundColor, showBorderRight } = props;
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box
      as='label'
      width='100%'
      mx='0'
      px='1'
      borderRightWidth='1px'
      borderRightStyle={showBorderRight ? 'solid' : 'none'}
      borderRightColor='neutral.300'
    >
      <input {...input} />
      <Box
        color='neutral.800'
        backgroundColor='neutral.0'
        borderColor='transparent'
        {...checkbox}
        cursor='pointer'
        borderWidth='1px'
        borderRadius='md'
        transitionDuration='0.2s'
        _hover={{
          bg: selectedBackgroundColor,
          color: 'neutral.0',
        }}
        _checked={{
          bg: selectedBackgroundColor,
          color: 'neutral.0',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        py={2}
        px={{ base: 0, md: 4 }}
        textAlign='center'
      >
        {props.children}
      </Box>
    </Box>
  );
};

type WorkOrderPriorityRadioProps = {
  updateWorkOrderPriority: (nextValue: WorkOrderPriority) => void;
  priority: WorkOrderPriority;
} & FlexProps;
const WorkOrderPriorityRadio: FC<WorkOrderPriorityRadioProps> = (
  props: WorkOrderPriorityRadioProps
) => {
  const { priority, updateWorkOrderPriority, ...flexProps } = props;
  const options = workOrderPriorityLabelOptions;
  const { t } = useTranslation();

  const { getRootProps, getRadioProps, setValue } = useRadioGroup({
    defaultValue: priority,
    onChange: updateWorkOrderPriority,
  });

  useEffect(() => {
    setValue(priority);
  }, [priority, setValue]);

  const group = getRootProps();
  return (
    <Flex
      width='100%'
      border='1px'
      borderRadius='4px'
      borderColor='neutral.300'
      padding='1'
      justifyContent='space-between'
      {...group}
      {...flexProps}
    >
      {options.map((option, index) => {
        const radio = getRadioProps({ value: option.value });
        return (
          <RadioCard
            key={option.value}
            selectedBackgroundColor={option.color}
            {...radio}
            showBorderRight={index !== options.length - 1}
          >
            {t(`priority.${option.value}`)}
          </RadioCard>
        );
      })}
    </Flex>
  );
};

export default WorkOrderPriorityRadio;
