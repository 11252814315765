import DateTimePicker from '@/common/components/DateTimePicker';
import DisplayDiffDownTime from '@/common/components/DisplayDiffDownTime';
import { isFromDateAfterToDate } from '@/utils/date/date';
import { TASK } from '@/utils/i18n/constants';
import { Box } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const WorkOrderStartEndDatePicker = () => {
  const { i18n, t } = useTranslation(TASK);
  const {
    getValues,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useFormContext();

  const STOPPAGE = {
    startAt: 'stoppageStartAt',
    endAt: 'stoppageEndAt',
  };

  const handleStartChange = (name: string, newValue: string | undefined) => {
    clearErrors(name);
    setValue(name, newValue);
    if (!getValues(STOPPAGE.endAt) || isFromDateAfterToDate(newValue, getValues(STOPPAGE.endAt))) {
      setValue(STOPPAGE.endAt, newValue);
    }
  };

  const handleEndChange = (name: string, newValue: string | undefined) => {
    clearErrors(name);
    if (isFromDateAfterToDate(getValues(STOPPAGE.startAt), newValue)) {
      setError(name, {
        type: 'custom',
        message: t('invalid-start-and-end-time', { ns: 'errors' }),
      });
      setTimeout(() => {
        clearErrors(name);
      }, 3000);
    } else {
      setValue(name, newValue);
    }
  };

  return (
    <Box bg='neutral.0' borderRadius='md' px={4} py={2} mx={2} my={4}>
      <DateTimePicker
        label={t('date.down-time-start-time')}
        ariaLabel='stoppage-start-at'
        value={getValues(STOPPAGE.startAt)}
        onChange={(newValue) => handleStartChange(STOPPAGE.startAt, newValue)}
        locale={i18n.language}
      />
      <DateTimePicker
        label={t('date.down-time-end-time')}
        ariaLabel='stoppage-end-at'
        value={getValues(STOPPAGE.endAt)}
        onChange={(newValue) => handleEndChange(STOPPAGE.endAt, newValue)}
        isInvalid={!!errors.stoppageEndAt}
        errorMessage={errors.stoppageEndAt && (errors.stoppageEndAt.message as string)}
        locale={i18n.language}
        sx={{ mt: 4 }}
      />
      <DisplayDiffDownTime
        startAt={getValues(STOPPAGE.startAt)}
        endAt={getValues(STOPPAGE.endAt)}
      />
    </Box>
  );
};

export default WorkOrderStartEndDatePicker;
