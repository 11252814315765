import { Box } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { memo } from 'react';
import { FreeMode } from 'swiper/modules';

export type ItemPickerProps = {
  initialItem: number;
  items: string[] | number[];
  onItemChange: (index: number) => void;
  itemFormatter?: (item: string | number) => string;
};

const ItemPicker = memo(
  function ItemPicker(props: ItemPickerProps) {
    const { initialItem, items, onItemChange, itemFormatter } = props;

    return (
      <Box className='picker'>
        <Swiper
          loop
          centeredSlides
          slidesPerView={3}
          direction='vertical'
          initialSlide={initialItem}
          onSlideChange={(swiper) => {
            onItemChange(swiper.realIndex);
          }}
          slideToClickedSlide
          freeMode={{
            enabled: true,
            momentumRatio: 0.5,
            momentumVelocityRatio: 0.5,
            sticky: true,
          }}
          modules={[FreeMode]}
        >
          {items.map((item) => {
            return (
              <SwiperSlide key={item}>
                {({ isActive, isPrev, isNext }) => (
                  <Box
                    className='time-item'
                    sx={{
                      transform: (isNext && 'rotateX(-320deg)') || (isPrev && 'rotateX(-40deg)'),
                      opacity: isActive ? 1 : 0.5,
                    }}
                  >
                    {itemFormatter ? itemFormatter(item) : item}
                  </Box>
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    );
  },
  // initialValueはあくまで最初の値を指定するので変化しても再描画しない
  (prev, next) =>
    prev.items === next.items &&
    prev.itemFormatter === next.itemFormatter &&
    prev.onItemChange === next.onItemChange
);

export default ItemPicker;
