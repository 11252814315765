import type { FC } from 'react';
import UserSelectInput, { UserType } from '../UserSelectInput';

type ChangeInputType = { customFieldId: number; userIds: string[] };

type CustomFieldUserProps = {
  customFieldId: number;
  label: string;
  onChange: (input: ChangeInputType) => void;
  userIds: UserType[];
};

const CustomFieldUser: FC<CustomFieldUserProps> = (props: CustomFieldUserProps) => {
  const { customFieldId, label, userIds, onChange } = props;

  const handleUserSelectInput = (userValues: { userId: string }[]) => {
    onChange({ customFieldId, userIds: userValues.map((value) => value.userId) });
  };

  return <UserSelectInput label={label} values={userIds} onChange={handleUserSelectInput} />;
};

export default CustomFieldUser;
